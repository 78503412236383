import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='SECURE INSURANCE IN WASHINGTON' activeCard='washington' image={'/Washington_BG.jpg'}>
          <p>
            Are you a Washington resident looking for affordable, reliable health insurance? Apollo Insurance can help you find the providers you need. We work
            with you to navigate the difficult health insurance parameters and find the lowest cost insurance plans. You may be searching for individual health
            insurance or a life insurance plan, or you may be a small business owner considering the best group plan for your company. Regardless, we assist our
            Washington clients with health insurance, dental plans, financial planning, and more! We are equipped to find on and off-market exchange options,
            such as those offered by Aliera, United Healthcare, and National General.
          </p>
          <p>
            The health insurance industry can be a chaotic place. Trust Apollo Insurance to navigate you through the fine print and find health care plans in
            Washington. Our agents are working tirelessly to find clear and affordable solutions for you. Let us help you tackle the new challenges in the new
            year. Contact us today for a quote at (913) 279-0077!{' '}
          </p>

          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance shifting to the consumer, gap insurance plans have become the most important and popular plan we sell
            today.{' '}
          </p>
        </Content>
      </div>
    </Layout>
  );
};
